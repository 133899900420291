import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { _ } from "common";
import CategorySectionHeader from "components/CategorySectionHeader";

//swiper package and modules
import { Swiper, SwiperSlide } from "swiper/react";
//swiper style needed*
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css";
import HomeMainCategoryItem from "components/categories/HomeMainCategoryItem";
import { useIsMobile } from "hooks";
import { useMainCategory } from "hooks/useMainCategory";

const CategoriesSection: FC = () => {
  const isMobile = useIsMobile();

  const { mainCategories } = useMainCategory();

  // const showAllCategories = () => {
  //   if (categoriesLimit === undefined) {
  //     setCategoriesLimit(categoryInitialLimit);
  //   } else {
  //     setCategoriesLimit(undefined);
  //   }
  // };

  return (
    <Box flex="1 1 auto" minWidth="0px" mt={isMobile ? 1 : 4}>
      {!isMobile ? (
        <>
          <CategorySectionHeader title="Categories" />
          <Swiper
            spaceBetween={1}
            simulateTouch={true}
            breakpoints={{
              0: { slidesPerView: 2.25 },
              600: { slidesPerView: 2.25 },
              900: { slidesPerView: 2.25 },
              1200: { slidesPerView: 4 },
            }}
          >
            <Grid container spacing={1}>
              {_.map(mainCategories, (category, ind) => (
                <SwiperSlide key={ind}>
                  <Grid item py={1}>
                    <HomeMainCategoryItem mainCategory={category} />
                  </Grid>
                </SwiperSlide>
              ))}
            </Grid>
          </Swiper>
        </>
      ) : (
        <Grid container>
          {_.map(mainCategories, (category, ind) => (
            <Grid item md={2} sm={4} xs={6} py={1} key={ind}>
              <HomeMainCategoryItem mainCategory={category} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default CategoriesSection;
