import { useEffect } from "react";
import { useIsMobile } from "./useIsMobile";

const useNearEndScroll = (callback: () => void, paused?: boolean, requestPage: number = 2) => {
  const isMobile = useIsMobile();
  const height = isMobile ? 800 : 400;
  const debounceTime = 100;

  useEffect(() => {
    let debounceTimeoutId = null;

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollBottom = documentHeight - (scrollTop + windowHeight);

      if (scrollBottom < height && !paused && requestPage && requestPage % 2 === 0) {
        if (debounceTimeoutId !== null) {
          clearTimeout(debounceTimeoutId);
        }

        debounceTimeoutId = setTimeout(() => {
          callback();
        }, debounceTime);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [callback, height, paused, requestPage]);
};

export default useNearEndScroll;
