import { Box } from "@mui/material";
import CarouselCard1 from "components/carousel-cards/CarouselCard1";
import CategorySectionHeader from "components/CategorySectionHeader";
import { NextPage } from "next";
import { navigationRoutes } from "common/constants/navigation-routes";
import Link from "next/link";

const PCbuilderSection: NextPage = () => {
  return (
    <Box mt={3}>
      <Link href={navigationRoutes.diyBuilder} passHref>
        <a>
          <CarouselCard1
            carousel={{ image_url: "/assets/images/banners/pcbuilder.png" }}
          />
        </a>
      </Link>
    </Box>
  );
};

export default PCbuilderSection;
