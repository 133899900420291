import { Box } from "@mui/system";
import { H3, Span } from "components/Typography";
import React, { FC } from "react";

// component props interface
interface CountBoxProps {
  digit: number;
  title: string;
}

const CountBox: FC<CountBoxProps> = ({ digit, title }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <H3>{!isNaN(digit) ? digit : 0}</H3>
      <Span mt={-0.3} color="grey.600" fontSize="14px" fontWeight="600">
        {title}
      </Span>
    </Box>
  );
};

export default CountBox;

CountBox.defaultProps = {
  digit: 365,
  title: "DAYS",
};
