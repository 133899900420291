import { Divider, Container, Typography, Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "common";
import { H3, H4 } from "components/Typography";
import React, { FC, useState } from "react";

interface Props {}

export const PCWorthOverview: FC<Props> = () => {
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  return (
    <>
      <Divider sx={{ mt: "3em", borderBottom: "4px solid black" }} />
      <Container
        sx={{
          mt: "2em",
          maxHeight: isInfoOpen ? "100%" : "25vh",
          overflow: "hidden",
          maskImage: isInfoOpen
            ? "none"
            : "linear-gradient(to bottom, black 70%, transparent 100%)",
          WebkitMaskImage: isInfoOpen
            ? "none"
            : "linear-gradient(to bottom, black 70%, transparent 100%)",
        }}
      >
        <H3 mb="1em" fontSize="1.5em">
          PC Worth | Your Trusted E-commerce Store for Computer Parts
        </H3>
        <Typography mb="1em">
          PC Worth is a computer retail store in the Philippines, located in
          Manila.
        </Typography>

        <Typography mb="1em">
          Explore our wide range of high-quality computer parts, computer
          accessories, computer components from AMD, Intel, Nvidia, and Radeon
          such as CPU, CPU cooler, CPU cooling, graphics card, GPU, fan, hard
          disk drives, memory, monitor, motherboards, RAM, solid-state drives,
          power supplies, PC cases, and processors.
        </Typography>
        <Typography mb="1em">
          Many users believe that desktop PCs are more powerful than laptops,
          that is why PC Worth offers amazing tech deals to build a reliable
          home PC, gaming PC build and desktop systems, that fits our customers’
          needs.
        </Typography>
        <Typography mb="1em">
          PC Worth offers AI Builder. With AI Builder, learning and
          inexperienced users in PC building can have a peek at their dream PC.
          This PC builder ensures convenience without compromising the
          compatibility of the suggested gaming pc build and PC builds.
        </Typography>
        <Typography mb="1em">
          We build your ideal PC at the best price because at PC Worth, we offer
          quality computer products for your customized PC build and gaming PC
          build.
        </Typography>
        <Typography mb="1em">
          As what our customers says, PC Worth is the best online computer store
          in Manila and one of the Top 10 Best Computer Shop near Manila. Shop
          computer now at PC Worth, the leading computer retailer in the
          Philippines.
        </Typography>
        <H4 mb="1em">Top-notch Customer Service</H4>
        <Typography mb="1em">
          We understand that building a PC is a challenge for some. Amazing
          after sales support is guaranteed because PC Worth is committed to
          provide top-notch customer service to ensure customers’ satisfaction.
          We have dedicated sales and after sales support team that are equipped
          with broad-gauge knowledge of our products and services.
        </Typography>
        <Typography mb="1em">
          PC Worth is dedicated to provide the utmost care and knowledge about
          the products to customers before making a purchase. We are committed
          to be with you in every step of the way in acquiring your dream pc
          build. More than that, PC Worth is known for our expert pc builders /
          tech team that provides the best cable management and best computer
          assembly for our pc builds and gaming pc build.
        </Typography>
        <Typography mb="1em">
          At PC Worth, we believe in inclusivity, wherein every customer needs
          are unique and must be addressed. That is why our sales and after
          sales support team are ready to assist you, whether you are in our
          physical store or reaching out to us via online.
        </Typography>
        <Typography mb="1em">
          We assure you that PC Worth is with you all throughout the process. If
          you have any questions and encounter any issues in your order, do not
          hesitate to reach out to us through our e-mail and live chat via
          Facebook page.
        </Typography>
        <H4 mb="1em">Great Deals</H4>
        <Typography mb="1em">
          Building your dream PC while on a budget? PC Worth offers affordable
          and wide range of high-quality computer parts, computer accessories,
          computer components, gaming desktop, and office desktop on clearance
          sale.
        </Typography>
        <Typography mb="1em">
          PC Worth offers amazing tech deals for pc parts, computer components,
          and computer accessories. For our customers’ convenience, our
          pricelist is also available on our e-commerce.
        </Typography>

        <H4 mb="1em">Build Gallery</H4>
        <Typography mb="1em">
          PC Worth’s Build Gallery features latest pc builds and gaming setup of
          our customers. For those who are looking for pc build inspirations,
          you can browse the Build Gallery with entry, mid, and premium level pc
          builds.
        </Typography>
        <H4 mb="1em">Do It Yourself Builder</H4>
        <Typography mb="1em">
          If you are an experienced pc enthusiast, PC Worth offers Do it
          Yourself Builder where you can build your own PC. To use this tool,
          you can select your desired PC components such as processor,
          motherboard, memory, GPU, SSD, HDD, PSU, casing, fan, and accessories
          such as monitor, keyboard, and mouse.
        </Typography>
        <Typography mb="1em">
          For clarifications about compatibility, kindly send us a message on
          our Facebook page and let our designated team further assist you.
        </Typography>
        <H4 mb="1em">PC Package</H4>
        <Typography mb="1em">
          How much does a PC cost in the Philippines? For as low as Php13,000,
          you can acquire a reliable home PC, office desktop, and gaming desktop
          through our PC Package, which is much affordable than Gilmore PCs. For
          hassle-free PC building, pre-built PCs are available in our physical
          store and e-commerce store. We can assure our customers that these
          system unit only PC build are meticulously selected to provide
          exceptional performance for different applications like gaming, video
          editing, and productivity.
        </Typography>
        <H4 mb="1em">AI Builder</H4>
        <Typography mb="1em">
          We understand that there are people who are learning and inexperienced
          in PC Building. Therefore, we offer our very own AI Builder wherein
          the user can visualize their dream PC build with the help of our PC
          parts picker. Our AI Builder is tailored for beginner and learning
          users who want their own PC build without compromising the
          compatibility and of their future PC components.
        </Typography>
        <H4 mb="1em">Mode of Payment</H4>
        <Typography mb="1em">
          PC Worth offers flexible payment options. For walk-in customers, you
          can shop now, pay later through BDO, BPI Bank Transfer, Gcash, GGives,
          and Billease. Cash, and Straight payment credit card via PayMongo,
          Homecredit credit and Credit card installment via BDO, BPI, EastWest,
          HSBC, and ChinaBank installment (Up to 12 months, zero interest).
          Meanwhile, for online customers, BDO, BPI Bank Transfer, Gcash,
          GGives, Billease, Cash, and Straight payment credit card via PayMongo
          are available.
        </Typography>
        <H4 mb="1em">Shipping and Delivery</H4>
        <Typography mb="1em">
          For our online customers, we ensure that you will receive your
          purchased pc build, gaming pc build, computer parts, or computer
          accessories safely through same day delivery or next day delivery. Our
          designated team assures that your order is well-packaged and will
          arrive safely at your doorstep. PC Worth offers nationwide delivery
          through our trusted logistic partner. For same day delivery, kindly
          coordinate to our designated team.
        </Typography>
      </Container>
      <Button
        disableRipple
        sx={{
          color: colors.error,
          pl: "2em",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        endIcon={
          isInfoOpen ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )
        }
        onClick={() => setIsInfoOpen(!isInfoOpen)}
      >
        {isInfoOpen ? "Show Less" : "Show More"}
      </Button>
    </>
  );
};
