import { Box } from "@mui/material";
import CarouselCard1 from "components/carousel-cards/CarouselCard1";
import { NextPage } from "next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect } from "react";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import { useAppDispatch } from "hooks/use-app-dispatch";

const { selectors: bannerSelectors, actions: bannerActions } = modules.banner;

const TarpaulinSection: NextPage = () => {
  const banners = useAppSelector(bannerSelectors.selectBanners);
  const dispatch = useAppDispatch();

  const getBannersInternal = async () => {
    dispatch(bannerActions.getBannersThunk());
  };

  useEffect(() => {
    getBannersInternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2} borderRadius={2} overflow="hidden">
      <Swiper
        simulateTouch={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        pagination={{ dynamicBullets: true }}
        modules={[Pagination, Autoplay]}
      >
        {banners?.map((banner, i) => (
          <SwiperSlide key={`${banner?.id}${i}`}>
            <CarouselCard1
              href={banner?.link}
              carousel={{ image_url: banner?.img_url }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default TarpaulinSection;
