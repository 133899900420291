import { Card } from "@mui/material";
import { colors } from "common";
import { Small } from "components/Typography";
import { MainCategoryItem } from "modules/category/types";
import { FC } from "react";
import { useRouter } from "next/router";
import LazyImage from "components/LazyImage";
import { useIsMobile } from "hooks";

// =============================================================
type CategoryMenuItemProps = {
  mainCategory?: MainCategoryItem;
};
// =============================================================

const HomeMainCategoryItem: FC<CategoryMenuItemProps> = ({ mainCategory }) => {
  const router = useRouter();
  const isMobile = useIsMobile();

  const onClickCategoryItem = () => {
    /* This version of the router.push is specifically designed for the main categories section with manufacturers on the homepage.
     *  When a user clicks on this section, it displays all available products under that main category
     *  without the need to select a specific manufacturer beforehand. */

    if (mainCategory?.slug) {
      router.push({
        pathname: `product/search/${""}`,
        query: {
          slug: mainCategory?.slug,
          page: 1,
        },
      });
    }
  };

  return (
    <Card
      onClick={onClickCategoryItem}
      sx={{
        p: 1,
        mb: 1,
        mr: 1,
        height: 100,
        display: "flex",
        flexDirection: isMobile ? "column" : "flex",
        backgroundColor: colors.common.white,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 2,
        borderWidth: 1,
        cursor: "pointer",
        scrollMarginTop: 200,
        ":hover": {
          backgroundColor: "#E4F2FF !important",
        },
      }}
    >
      <LazyImage
        width={70}
        height={50}
        objectFit="contain"
        src={mainCategory.icon || "/assets/images/sad_red_hair.png"}
        alt={mainCategory?.name || ""}
      />
      <Small
        fontWeight="600"
        textAlign="center"
        mt={1}
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
      >
        {mainCategory?.name?.toUpperCase()}
      </Small>
    </Card>
  );
};

export default HomeMainCategoryItem;
