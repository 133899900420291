import { Card, Grid } from "@mui/material";
import {
  getTimeLeftInHours,
  colors,
  currentDateNowConst,
  formatCurrency,
  formatCurrencySRP,
  productDetailsPageUrl,
} from "common";
import { H3, H4, H6 } from "components/Typography";
import _ from "lodash";
import Link from "next/link";
import Countdown from "pages-sections/fashion-shop/countdown";
import { FC, useMemo } from "react";
import BazarImage from "components/BazarImage";
import { FlashDealsItem } from "modules/flash-deal/types";
import { FlexRow } from "components/flex-box";
import { Box } from "@mui/system";
import BorderLinearProgress from "components/loader/BorderLinearProgress";

// ================================================================
interface Props {
  flashDealData?: FlashDealsItem;
}
// ================================================================

const FlashDealItem: FC<Props> = ({ flashDealData }) => {
  const linearProgressValue = useMemo(() => {
    const dealEndDate = flashDealData?.deal_end
      ? new Date(flashDealData?.deal_end)
      : currentDateNowConst;

    const hoursLeft = getTimeLeftInHours(dealEndDate) || 0;
    return hoursLeft > 100 ? 100 : hoursLeft;
  }, [flashDealData?.deal_end]);

  return (
    <Link
      href={productDetailsPageUrl(
        flashDealData?.slug,
        flashDealData?.product_uuid
      )}
      passHref
    >
      <Card elevation={0.3} sx={{ p: 2, cursor: "pointer" }}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{ backgroundColor: colors.common.white, borderRadius: 2 }}
        >
          <Grid
            item
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BazarImage
              alt="flashDealImg"
              height={110}
              src={
                flashDealData?.thumbnail ||
                "/assets/images/kachi/kachi-crying.png"
              }
            />
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <H4
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {flashDealData?.product_name}
            </H4>

            <FlexRow alignItems="center">
              <H3 color="primary.main">
                {formatCurrency(flashDealData?.amount || 99999999)}
              </H3>
              <H6
                ml={1}
                mt={0.3}
                sx={{ textDecoration: "line-through", color: colors.grey[600] }}
              >
                {formatCurrencySRP(flashDealData?.amount)}
              </H6>
            </FlexRow>
            <BorderLinearProgress
              sx={{ my: 0.5 }}
              variant="determinate"
              value={linearProgressValue}
            />
            <Box mt={1}>
              {flashDealData?.stocks <= 2 ? (
                <H6 color={colors.error} sx={{ fontWeight: "700" }}>
                  {flashDealData?.stocks} STOCKS LEFT !!!
                </H6>
              ) : null}

              <Countdown expireDate={new Date(flashDealData?.deal_end)} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

export default FlashDealItem;
