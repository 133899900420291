import { Box, Grid } from "@mui/material";
import { _ } from "common";
import CategorySectionHeader from "components/CategorySectionHeader";
import { ProductsSkeleton } from "components/skeletons";
import { FC, useEffect } from "react";
import FlashDealItem from "components/flash-deal/FlashDealItem";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import { useFlashDeals } from "hooks/use-flash-deals";

const FlashDealSection: FC = () => {
  const { flashDealItems, isFlashDealLoading, getFlashDealsRequestThunk } =
    useFlashDeals();

  const getFlashDealsList = () => {
    getFlashDealsRequestThunk();
  };

  useEffect(() => {
    getFlashDealsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex="1 1 auto" minWidth="0px" mt={4}>
      {flashDealItems?.length > 0 ? (
        <CategorySectionHeader
          title="Flash Deals"
          seeMoreLinkTxt="See All"
          seeMoreLink="/flash-deals"
        />
      ) : null}

      <Swiper
        spaceBetween={5}
        breakpoints={{
          0: { slidesPerView: 1.25 },
          600: { slidesPerView: 1.25 },
          900: { slidesPerView: 2.25 },
          1200: { slidesPerView: 2.25 },
          1536: { slidesPerView: 2.25 },
        }}
      >
        <Grid container spacing={1}>
          {_.map(flashDealItems, (item, ind) => (
            <SwiperSlide>
              <Grid item md={2} sm={4} xs={6}>
                <FlashDealItem flashDealData={item} />
              </Grid>
            </SwiperSlide>
          ))}
          {isFlashDealLoading
            ? _.map([1, 2, 3, 4, 5, 6], (item, ind) => (
                <Grid item md={2} sm={4} xs={6} key={ind}>
                  <ProductsSkeleton />
                </Grid>
              ))
            : null}
        </Grid>
      </Swiper>
    </Box>
  );
};

export default FlashDealSection;
