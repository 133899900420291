import { FC } from "react";
import Link from "next/link";
import { Grid, styled } from "@mui/material";

import BazarImage from "components/BazarImage";
import { FlexBetween } from "components/flex-box";

// styled component
const StyledBox = styled(FlexBetween)(({ theme }) => ({
  ".title": {
    fontSize: 50,
    marginTop: 0,
    lineHeight: 1.2,
    marginBottom: "1.35rem",
  },
  [theme.breakpoints.up("sm")]: {
    ".grid-item": {
      minHeight: 424,
      display: "flex",
      alignItems: "baseline",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    paddingLeft: 0,
    ".title": { fontSize: 32 },
  },
  [theme.breakpoints.down("xs")]: {
    ".title": { fontSize: 16 },
    ".title + *": { fontSize: 13 },
    ".button-link": { height: 36, padding: "0 1.5rem", fontSize: 13 },
  },
}));

// ==================================================
type CarouselCard1Props = {
  href?: string;
  carousel?: any;
};
// ==================================================

const CarouselCard1: FC<CarouselCard1Props> = ({ carousel, href }) => {
  return (
    <StyledBox>
      {/* This Hurts tho */}
      {href ? (
        <Link href={href} passHref>
          <Grid container>
            <Grid item xs={12}>
              <BazarImage
                alt="apple-watch-1"
                src={carousel.image_url}
                sx={{
                  mx: "auto",
                  // maxHeight: 400,
                  display: "block",
                  maxWidth: "100%",
                }}
              />
            </Grid>
          </Grid>
        </Link>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <BazarImage
              alt="apple-watch-1"
              src={carousel.image_url}
              sx={{
                mx: "auto",
                // maxHeight: 400,
                display: "block",
                maxWidth: "100%",
              }}
            />
          </Grid>
        </Grid>
      )}
    </StyledBox>
  );
};

export default CarouselCard1;
