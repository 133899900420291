import { useCallback } from "react";

import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";

const { selectors: flashDealSelectors, actions: flashDealActions } =
  modules.flashDeal;

export const useFlashDeals = () => {
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const flashDealItems = useAppSelector(flashDealSelectors.selectFlashDeals);
  const isFlashDealLoading = useAppSelector(
    flashDealSelectors.selectFlashDealsLoading
  );

  /** ========== FUNCTIONS ========== */
  const getFlashDealsRequestThunk = useCallback(() => {
    dispatch(flashDealActions.getFlashDealsThunk({ limit: 50 }));
  }, [dispatch]);

  return {
    flashDealItems,
    isFlashDealLoading,

    getFlashDealsRequestThunk,
  };
};
