import { FC, memo } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import LazyImage from "components/LazyImage";
import { PcBundleData } from "modules/pc-bundle";
import { formatCurrency } from "common";

type Props = {
  pc_bundle_data: PcBundleData;
  onHandleNavigateDetails: () => void;
};

const Component: FC<Props> = ({ pc_bundle_data, onHandleNavigateDetails }) => {
  return (
    <Card
      sx={{
        height: "100%",
        margin: "auto",
        display: "flex",
        overflow: "hidden",
        borderRadius: "8px",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        transition: "all 250ms ease-in-out",
        p: 3,
      }}
      onClick={(e) => {
        e.preventDefault();
        onHandleNavigateDetails();
      }}
    >
      <LazyImage
        src={
          pc_bundle_data?.manufacturer_icon ||
          "/assets/images/kachi/kachi-crying.png"
        }
        alt={`pc-worth-bundle-img`}
        width={90}
        height={30}
        layout="fixed"
        objectFit="contain"
      />

      <LazyImage
        width={350}
        height={350}
        alt={`pc-worth-bundle-img`}
        loading="eager"
        objectFit="contain"
        src={
          pc_bundle_data?.pc_bundle_thumbnail ||
          "/assets/images/kachi/kachi-crying.png"
        }
        sx={{ mt: 3, cursor: "pointer" }}
        onClick={onHandleNavigateDetails}
      />
      <Box sx={{ display: "grid", placeItems: "center", mt: 1, width: "100%" }}>
        <Typography
          variant="h6"
          fontWeight={700}
          mt={1.5}
          textAlign={"center"}
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textAlign: "center",
          }}
        >
          {pc_bundle_data?.pc_bundle_title}
        </Typography>
        <Typography variant="h6" fontWeight={700} color={"primary"}>
          {formatCurrency(pc_bundle_data?.pc_bundle_promo_amount)}
        </Typography>
        <Typography color={"primary"} sx={{ textDecoration: "line-through" }}>
          {formatCurrency(pc_bundle_data?.pc_bundle_amount)}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 1, cursor: "pointer" }}
          onClick={onHandleNavigateDetails}
        >
          VIEW PC BUNDLE
        </Button>
      </Box>
    </Card>
  );
};

export const PcBundleCard = memo(Component);
