import fs from "fs";
import path from "path";

import ShopLayout1 from "components/layouts/ShopLayout1";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useLoginStatus } from "hooks/useLoginStatus";
import modules from "modules";
import { NextPage } from "next";
import Section6 from "pages-sections/home/Section6";
import { useEffect } from "react";

interface Props {}

const { actions: optionsActions } = modules.options;

const IndexPage: NextPage<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const { isUserLogin } = useLoginStatus();
  const isLogin = isUserLogin;
  const getSocialLoginOptions = async () => {
    await dispatch(optionsActions?.getSocialBtnThunk());
  };

  useEffect(() => {
    if (!isLogin) {
      getSocialLoginOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopLayout1>
      {/* TODO: Transfer data components from Section6 to Here */}
      <Section6 />
    </ShopLayout1>
  );
};

export default IndexPage;
