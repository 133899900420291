import { Box } from "@mui/material";
import { currentDateNow, dateToMs } from "common";
import { FlexBox } from "components/flex-box";
import { Paragraph } from "components/Typography";
import React, { FC, useCallback, useEffect, useState } from "react";
import CountBox from "./CountBox";

// component props interface
interface CountDownProps {
  expireDate: string | Date;
}

const initialState = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const dateNow = currentDateNow();

const Countdown: FC<CountDownProps> = ({ expireDate = dateNow }) => {
  const [timeLeft, setTimeLeft] = useState(initialState);

  const calculateTimeLeft = useCallback(() => {
    const difference = dateToMs(expireDate) - new Date().getTime();
    // if date expire
    if (difference < 0) return initialState;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }, [expireDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <Box>
      <Paragraph fontWeight="700" mb={-0.5}>
        Offer ends in:
      </Paragraph>
      <FlexBox
        sx={{
          display: "flex",
          width: "90%",
          justifyContent: "space-between",
          height: "auto",
        }}
      >
        <CountBox digit={timeLeft.days} title="DAYS" />
        <CountBox digit={timeLeft.hours} title="HOURS" />
        <CountBox digit={timeLeft.minutes} title="MINS" />
        <CountBox digit={timeLeft.seconds} title="SECS" />
      </FlexBox>
    </Box>
  );
};

export default Countdown;
