import { Box, CircularProgress, Grid } from "@mui/material";
import { _ } from "common";
import CategorySectionHeader from "components/CategorySectionHeader";
import ProductCard1 from "components/product-cards/ProductCard1";
import { ProductsSkeleton } from "components/skeletons";
import { FC, useCallback, useEffect, useMemo } from "react";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";
import useNearEndScroll from "hooks/use-near-end-scroll";
import { H5 } from "components/Typography";
import BazarButton from "components/BazarButton";

const { actions: productsActions, selectors: productsSelectors } =
  modules.products;
const { selectors: categorySelectors } = modules.category;

const AllProductsSection: FC = () => {
  const dispatch = useAppDispatch();
  const homeProducts = useAppSelector(productsSelectors.selectHomeProducts);
  const selectedCategoryItem = useAppSelector(
    categorySelectors.selectCategoryItem
  );
  const pageData = useAppSelector(
    productsSelectors.selectProductsHomePaginationMeta
  );
  const productsLoading = useAppSelector(
    productsSelectors.selectHomeProductsLoading
  );
  const homeProductsNextPageLoading = useAppSelector(
    productsSelectors.selectHomeProductsNextPageLoading
  );
  const productsHomePageRequest = useAppSelector(
    productsSelectors.selectHomeProductsRequest
  );

  const getNextPageOfHomeProducts = useCallback(() => {
    if (homeProductsNextPageLoading) {
      return;
    }
    dispatch(productsActions.getHomePageNextPageProductsThunk());
  }, [dispatch, homeProductsNextPageLoading]);

  const getAllProducts = () => {
    dispatch(productsActions.getHomePageProductsThunk());
  };

  const isLastPage = useMemo(
    () => pageData?.current_page >= pageData?.last_page,
    [pageData?.current_page, pageData?.last_page]
  );

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNearEndScroll(
    getNextPageOfHomeProducts,
    false,
    productsHomePageRequest?.page
  );

  return (
    <div id="home-all-products-section">
      <Box flex="1 1 auto" minWidth="0px" mt={4}>
        <CategorySectionHeader
          title={selectedCategoryItem?.name || "All Products"}
          seeMoreLinkTxt="See All"
          seeMoreLink="/all-products"
        />
        <Grid container spacing={1}>
          {_.map(homeProducts, (item, ind) => (
            <Grid item md={2} sm={4} xs={6} key={ind}>
              <ProductCard1 hoverEffect productData={item} />
            </Grid>
          ))}
          {productsLoading
            ? _.map([1, 2, 3, 4, 5], (item, ind) => (
                <Grid item md={2} sm={4} xs={6} key={ind}>
                  <ProductsSkeleton />
                </Grid>
              ))
            : null}
        </Grid>

        {isLastPage ? (
          <Box color="text.secondary" textAlign="center" mt={2}>
            No more items to show
          </Box>
        ) : (
          <BazarButton
            color="secondary"
            variant="contained"
            disabled={homeProductsNextPageLoading}
            onClick={getNextPageOfHomeProducts}
            sx={{ mb: 4.5, width: "100%", height: 40, top: 30, bottom: 30 }}
          >
            {homeProductsNextPageLoading &&
            productsHomePageRequest?.page > 1 ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="info" size={20} />
                <H5
                  ml={1}
                >{`LOADING PAGE ${productsHomePageRequest?.page}`}</H5>
              </Box>
            ) : (
              "See More Products"
            )}
          </BazarButton>
        )}
      </Box>
    </div>
  );
};

export default AllProductsSection;
